import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import './AboutUs.css';

const AboutUs = () => (
  <Container className="AboutUs">
    <Card className="AboutUsCard">
      <Row className="FAQStartingRow align-items-center">
        <Col xs={12} className="text-center">
          <h1>About Us</h1>
        </Col>
      </Row>
      <Row className="AboutUsStartingRow align-items-center">
        <Col xs={12} md={6} className="text-center text-md-left">
          <h2>Meet Parker Throneberry</h2>
          <p className="aboutus-text">
            Hi there! I'm Parker Throneberry, the one-man team behind Printforgers.com. From coding the platform to operating the printers, I handle every aspect of the business personally.
          </p>
          <p className="aboutus-text">
            What began as a fun project for my resume has grown into something I'm truly passionate about. I've poured a lot of love and effort into this venture, and I'm excited to offer high-quality 3D printing services to you!
          </p>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <LazyLoadImage
            src={`${process.env.PUBLIC_URL}/AboutUs/parker-throneberry.webp`}
            alt="Parker Throneberry"
            effect="opacity"
            rounded="true"
            fluid="true"
          />
        </Col>
      </Row>
      
      <Row className="MiddleRow mt-5 align-items-center">
        <Col xs={12} md={6} className="text-center text-md-left">
          <h2 className="aboutus-section-title">Why I Do What I Do</h2>
          <p className="aboutus-text">
            My love for crafts, 3D printing, and creating new things drives everything I do. There's nothing more rewarding than bringing ideas to life. If you have a unique request that the <a href='/upload'>instant quote</a> tool can’t accommodate, please feel free to reach out via <a href="mailto:parker@printforgers.com">email</a>. I’m always eager to help and collaborate.
          </p>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <LazyLoadImage
            src={`${process.env.PUBLIC_URL}/AboutUs/sword-print-unpainted.webp`}
            alt="3D Printing"
            effect="opacity"
            rounded="true"
            fluid="true"
          />
        </Col>
      </Row>
      
      <Row className="mt-5 align-items-center">
        <Col xs={12} md={6} className="text-center text-md-left">
          <h2 className="aboutus-section-title">Get in Touch</h2>
          <p className="aboutus-text">
            I’m always happy to hear from you! Whether you have questions about an order, want to discuss our services, or just want to chat, drop me a line at <a href="mailto:parker@printforgers.com">parker@printforgers.com</a>. I’ll get back to you as soon as I can.
          </p>
          <div className="social-icons">
            <a href="https://www.linkedin.com/in/pht1207/" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-linkedin fa-2x"></i>
            </a>
            <a href="https://github.com/pht1207" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-github fa-2x"></i>
            </a>
            <a href="mailto:parker@printforgers.com" className="social-icon">
              <i className="fas fa-envelope fa-2x"></i>
            </a>
          </div>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <LazyLoadImage
            src={`${process.env.PUBLIC_URL}/AboutUs/graduation-photo.webp`}
            alt="Workspace"
            effect="opacity"
            rounded="true"
            fluid="true"
          />
        </Col>
      </Row>
    </Card>
  </Container>
);

export default AboutUs;
