import { useEffect, useState, useContext } from "react";
import axios from 'axios';
import { Button, Row, Col } from 'react-bootstrap';
import { HttpPopupContext } from "../HTTPPopup/HttpPopupContext";

function SelectMaterial(props) {
    const [materialList, setMaterialList] = useState([]);
    const { setResponse } = useContext(HttpPopupContext);

    useEffect(() => {
        const fetchData = async () => { 
            try {
                const response = await axios.get("https://printforgers.com/backend/HTTPGetFilamentTypes");
                setMaterialList(response.data.results);
                //console.log(response.data.results);
            } catch (error) {
                console.error('Error fetching data: ', error);
                setResponse(error.response);
            }
        };
        fetchData();
    }, []);

    return (
        <div className='SelectMaterialList'>
            <h1>Pick your material</h1>
            <p className="MaterialListDescription">*This is the material that your entire order will be printed in*</p>
            {materialList.map((object, index) => (
                <div key={index}>
                    <Row className='Material justify-content-between'>
                        <Col xs={8}>
                            <Row className="LeftColorColumn">
                                <Col xs={2}>
                                    <p>{object.materialName}</p>
                                </Col>
                                <Col xs={10} className='Description'>
                                    <p>{object.description}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={2} className='MaterialControls'>
                            <p>Price: +${(props.baseTotal * object.base_material_price_modifier).toFixed(2)}</p>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    props.setMaterial(object.materialName);
                                    props.setOptionStep("Color");
                                    props.setMaterialCostMultiplier(object.base_material_price_modifier);
                                }}
                            >
                                Select Material
                            </Button>
                        </Col>
                    </Row>
                    <br />
                </div>
            ))}
        </div>
    );
}

export default SelectMaterial;
