import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './Homepage.css';
import { Button, Container, Tooltip, OverlayTrigger, Card } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

function Homepage() {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/HomePage/headphone_stand.stl`;
    link.setAttribute('download', 'headphone_stand.stl');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      "Headphone Stand" by MakerBot is licensed under CC BY-SA 3.0. <a href="https://creativecommons.org/licenses/by-sa/3.0/" target="_blank" rel="noopener noreferrer">Learn more</a>.
    </Tooltip>
  );

  return (
      <Container className="Homepage">
      <Card className="terms-card">
        <Row className="HomepageStartingRow align-items-center">
          <Col xs={12} md={6} className='LeftStartingColumn'>
            <Row className='LeftStartingColumn'>
              <Col xs={12}>
                <h1 className="homepage-title">Affordable, High-Quality 3D Printing</h1>
                <p className="homepage-subtitle">Experience precise, top-notch 3D prints at unbeatable prices, all managed by a single dedicated individual. Your support keeps this one-man operation running smoothly!</p>
              </Col>
              <Col xs={12} md={6}>
                <Button variant='primary' className="InstantQuoteButton mt-3" onClick={() => navigateTo("/upload")}>
                  Upload Your Files Here
                </Button>
              </Col>
              <Col xs={12} md={6}>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip}
                >
                  <Button variant='secondary' className="ExampleFileButton mt-3" onClick={downloadFile}>
                    Example File
                  </Button>
                </OverlayTrigger>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="mt-4 mt-md-0">
            <Carousel activeIndex={index} onSelect={handleSelect} interval={3000} fade>
              <Carousel.Item>
                <LazyLoadImage
                  className="d-block w-100"
                  src={`${process.env.PUBLIC_URL}/HomePage/sword-print-unpainted.webp`}
                  alt="High-Quality 3D Prints"
                  effect="opacity"
                />
                <Carousel.Caption>
                  <h3>High-Quality 3D Prints</h3>
                  <p>Get your 3D models printed with precision and excellence.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <LazyLoadImage
                  className="d-block w-100"
                  src={`${process.env.PUBLIC_URL}/HomePage/armor-suit.webp`}
                  alt="3d-printed-mask"
                  effect="opacity"
                />
                <Carousel.Caption>
                  <h3>Large-Scale Prints</h3>
                  <p>Enjoy affordable pricing even for large-scale projects.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <LazyLoadImage
                  className="d-block w-100"
                  src={`${process.env.PUBLIC_URL}/HomePage/lantern-homepage.webp`}
                  alt="lantern-with-light-inside"
                  effect="opacity"
                />
                <Carousel.Caption>
                  <h3>Get Creative!</h3>
                  <p>Transform your ideas into reality with our 3D printing service.</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>

        <Row className="mt-5 text-center">
          <Col xs={12}>
            <h2 className="homepage-section-title">Why Choose Printforgers?</h2>
            <p className="small-business-note">Printforgers.com is a one-man operation driven by a passion for 3D printing. Your support is crucial and deeply appreciated!</p>
          </Col>
          <Col md={4} className="homepage-feature">
            <h4>Unbeatable Prices</h4>
            <p>Enjoy some of the most competitive prices around with no hidden fees or minimum order quantities.</p>
          </Col>
          <Col md={4} className="homepage-feature">
            <h4>Top-Quality Prints</h4>
            <p>With FDM printing using PLA, PETG, and ABS, I ensure that your prints are strong, durable, and precise.</p>
          </Col>
          <Col md={4} className="homepage-feature">
            <h4>Ideal for Large Projects</h4>
            <p>Affordable solutions make it easy to print larger items, perfect for both personal and professional projects.</p>
          </Col>
          <Col xs={12}>
            <h2 className="homepage-call-to-action"><a onClick={() => navigate('/upload')} style={{ color: 'rgb(255, 106, 0)', textDecoration: 'underline', cursor: 'pointer' }}>Start Your 3D Printing Project Today!</a></h2>
          </Col>
        </Row>
        </Card>
        <footer className="footer">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <p>Printforgers.com is proudly run by a single passionate 3D printing enthusiast. Thank you for supporting this small, dedicated venture!</p>
            </Col>
          </Row>
        </Container>
      </footer>
      </Container>
  );

}

export default Homepage;
